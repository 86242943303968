import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { Observable, of } from 'rxjs'
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators'

import { CalibrationAlertService } from '@app/modules/calibration/services/calibration-alert.service'
import { SectionLoaderEnum } from '@app/modules/shared/models/section-loader.enum'
import { AttachmentService } from '@app/services/attachment.service'
import { ResponseHandlerService } from '@app/services/response-handler.service'
import { ResponseHandlingStrategyBuilder } from '@app/services/response-handling-strategy.builder'
import { AppState } from '@app/store/app.store'
import * as AttachmentAction from './attachment.actions'

@Injectable()
export class AttachmentEffects {
    
    public deleteCalibrationAttachment: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(AttachmentAction.ActionType.DeleteCalibrationAttachment),
        switchMap((action: AttachmentAction.DeleteCalibrationAttachmentAction) =>
            this.responseHandlerService.query(() =>
                this.attachmentService.deleteCalibrationAttachment(action.calibrationId, [action.attachmentId]), this.customStrategySectionLoader
            ).pipe(map(succeeded => {
                if (succeeded) {
                    return {
                        workOrderNumber: action.workOrderNumber,
                        equipmentId: action.equipmentId,
                        calibrationId: action.calibrationId
                    }
                }
            }))
        ),
        switchMap(attachment => {
            if (attachment) {
                return [
                    new AttachmentAction.GetCalibrationAttachmentsAction(attachment.calibrationId)
                ]
            }
        })
    ))

    
    public getCalibrationAttachments: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(AttachmentAction.ActionType.GetCalibrationAttachments),
        switchMap((action: AttachmentAction.GetCalibrationAttachmentsAction) =>
            this.responseHandlerService.query(() =>
                this.attachmentService.getCalibrationAttachments(action.calibrationId),
                this.customStrategySectionLoader
            ).pipe(
                tap(attachmentList => this.calibrationAlertService.attachmentRequiredAlert(
                    action.calibrationId,
                    action.calibrationStatusId,
                    action.templateTypeId,
                    attachmentList
                )),
                map(attachmentList => new AttachmentAction.GetCalibrationAttachmentsSuccessAction(attachmentList)),
                catchError(() => of({ type: 'NO_ACTION' }))
            )
        )
    ))

    
    public replaceCalibrationAttachments: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(AttachmentAction.ActionType.ReplaceCalibrationAttachment),
        switchMap((action: AttachmentAction.ReplaceCalibrationAttachmentAction) => {
            const apiCall = () => {
                const attachmentIds = action.attachments
                    .filter(attachment => attachment.isDuplicated)
                    .map(item => item.attachmentId);
                return this.attachmentService.deleteCalibrationAttachment(action.calibrationId, attachmentIds).pipe(
                    concatMap(() => {
                        const files = action.attachments.map(attachment => attachment.file)
                        return this.attachmentService.uploadCalibrationAttachment(action.calibrationId, files)
                    })
                )
            }
            return this.responseHandlerService.query(apiCall, this.customStrategySectionLoader)
                .pipe(
                    map(response => new AttachmentAction.GetCalibrationAttachmentsAction(action.calibrationId)),
                    catchError(() => of({ type: 'NO_ACTION' }))
                )
        })
    ))

    
    public uploadCalibrationAttachment = createEffect(() => this.actions$.pipe(
        ofType(AttachmentAction.ActionType.UploadCalibrationAttachment),
        switchMap((action: AttachmentAction.UploadCalibrationAttachmentAction) => {
            return this.responseHandlerService.query(() =>
                this.attachmentService.uploadCalibrationAttachment(action.calibrationId, action.files), this.customStrategySectionLoader)
                .pipe(
                    switchMap(succeeded => {
                        if (succeeded) {
                            const attachment = {
                                workOrderNumber: action.workOrderNumber,
                                equipmentId: action.equipmentId,
                                calibrationId: action.calibrationId
                            }
                            return [
                                new AttachmentAction.GetCalibrationAttachmentsAction(attachment.calibrationId)
                            ]
                        }
                    }),
                    catchError(() => of({ type: 'NO_ACTION' }))
                )
        })
    ))

    
    public deleteEquipmentSettingAttachment: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(AttachmentAction.ActionType.DeleteEquipmentSettingAttachment),
        switchMap((action: AttachmentAction.DeleteEquipmentSettingAttachmentAction) =>
            this.responseHandlerService.query(() =>
                this.attachmentService.deleteEquipmentSettingAttachment(action.equipmentId, [action.attachmentId]), this.customStrategySectionLoader
            ).pipe(map(succeeded => {
                if (succeeded) {
                    return { equipmentId: action.equipmentId }
                }
            }))
        ),
        switchMap(attachment => {
            if (attachment) {
                return [
                    new AttachmentAction.GetEquipmentSettingAttachmentsAction(attachment.equipmentId)
                ]
            }
        })
    ))

    
    public getEquipmentSettingAttachments: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(AttachmentAction.ActionType.GetEquipmentSettingAttachments),
        switchMap((action: AttachmentAction.GetEquipmentSettingAttachmentsAction) =>
            this.responseHandlerService.query(() =>
                this.attachmentService.getEquipmentSettingAttachments(action.equipmentId),
                this.customStrategySectionLoader
            ).pipe(
                map(attachmentList => new AttachmentAction.GetEquipmentSettingAttachmentsSuccessAction(attachmentList)),
                catchError(() => of({ type: 'NO_ACTION' }))
            )
        )
    ))

    
    public replaceEquipmentSettingAttachments: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(AttachmentAction.ActionType.ReplaceEquipmentSettingAttachment),
        switchMap((action: AttachmentAction.ReplaceEquipmentSettingAttachmentAction) => {
            const apiCall = () => {
                const attachmentIds = action.attachments
                    .filter(attachment => attachment.isDuplicated)
                    .map(item => item.attachmentId);
                return this.attachmentService.deleteEquipmentSettingAttachment(action.equipmentId, attachmentIds).pipe(
                    concatMap(() => {
                        const files = action.attachments.map(attachment => attachment.file)
                        return this.attachmentService.uploadEquipmentSettingAttachment(action.equipmentId, files)
                    })
                )
            }
            return this.responseHandlerService.query(apiCall, this.customStrategySectionLoader)
                .pipe(
                    map(_ => new AttachmentAction.GetEquipmentSettingAttachmentsAction(action.equipmentId)),
                    catchError(() => of({ type: 'NO_ACTION' }))
                )
        })
    ))

    
    public uploadEquipmentSettingAttachment = createEffect(() => this.actions$.pipe(
        ofType(AttachmentAction.ActionType.UploadEquipmentSettingAttachment),
        switchMap((action: AttachmentAction.UploadEquipmentSettingAttachmentAction) => {
            return this.responseHandlerService.query(() =>
                this.attachmentService.uploadEquipmentSettingAttachment(action.equipmentId, action.files), this.customStrategySectionLoader)
                .pipe(
                    switchMap(succeeded => {
                        if (succeeded) {
                            return [
                                new AttachmentAction.GetEquipmentSettingAttachmentsAction(action.equipmentId)
                            ]
                        }
                    }),
                    catchError(() => of({ type: 'NO_ACTION' }))
                )
        })
    ))

    private customStrategySectionLoader = new ResponseHandlingStrategyBuilder()
        .useRethrowError()
        .useShowToastrOnError(this.toastr)
        .useShowSectionLoader(this.store, SectionLoaderEnum.Attachment)
        .responseStrategy

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private responseHandlerService: ResponseHandlerService,
        private calibrationAlertService: CalibrationAlertService,
        private attachmentService: AttachmentService,
        private toastr: ToastrService
    ) { }
}
