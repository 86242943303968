import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { isNotAValue } from '@app/utils/app-utils.function'
import { FlamingoFormDeleteDTO } from '@app/modules/flamingo/models/flamingo-form.model'

@Injectable({
    providedIn: 'root'
})
export class QueryBuilderService {

    private httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/x.cameljson'
    })

    private headersForFileUpload = new HttpHeaders({
        Accept: 'application/x.cameljson'
    })

    constructor(private httpClient: HttpClient) { }

    /**
     * Angular native encoder uses a different encoding standard (RFC3986)
     * and does not play well with our backend. Thus, manual encoding is used.
     */
    public constructUrlWithParameter(baseUrl: string, parameters?: any): string {
        if (!parameters) {
            return baseUrl
        }

        return Object.keys(parameters)
            .reduce((combined, key) =>
                !isNotAValue(parameters[key]) ? combined + `${key}=${encodeURIComponent(parameters[key])}&` : combined, baseUrl + '?'
            )
            .replace(/&$/, '')
    }

    public delete<T>(baseUrl: string, parameters?: any): Observable<HttpResponse<T>> {
        return this.httpClient.delete<T>(
            this.constructUrlWithParameter(baseUrl, parameters),
            {
                headers: this.httpHeaders,
                observe: 'response',
                withCredentials: true
            })
    }

    public get<T>(baseUrl: string, parameters?: any): Observable<HttpResponse<T>> {
        return this.httpClient.get<T>(
            this.constructUrlWithParameter(baseUrl, parameters),
            {
                headers: this.httpHeaders,
                observe: 'response',
                withCredentials: true
            })
    }

    public getWithToken<T>(baseUrl: string, token: string, parameters?: any): Observable<HttpResponse<T>> {
        const header = new HttpHeaders({
            Authorization: 'Bearer ' + token
        })
        return this.httpClient.get<T>(
            this.constructUrlWithParameter(baseUrl, parameters),
            {
                headers: header,
                observe: 'response'
            })
    }

    /**
     * Get File while exposing HTTP Events for progress tracking
     * *** Observing Progress is expensive and should only be used when really needed
     */
    public getFileWithProgress(baseUrl: string): Observable<HttpEvent<Blob>> {
        return this.httpClient.get(
            this.constructUrlWithParameter(baseUrl),
            {
                headers: this.httpHeaders,
                observe: 'events',
                responseType: 'blob',
                withCredentials: true,
                reportProgress: true
            }
        )
    }

    public getFile(baseUrl: string, parameters?: any): Observable<HttpResponse<Blob>> {
        return this.httpClient.get(
            this.constructUrlWithParameter(baseUrl, parameters),
            {
                headers: this.httpHeaders,
                observe: 'response',
                responseType: 'blob',
                withCredentials: true,
            }
        )
    }

    public post<T>(baseUrl: string, body?: any): Observable<HttpResponse<T>> {
        return this.httpClient
            .post<T>(baseUrl,
                body,
                {
                    headers: this.httpHeaders,
                    observe: 'response',
                    withCredentials: true
                }
            )
    }

    public postUpload<T>(baseUrl: string, file: FormData): Observable<HttpResponse<T>> {
        return this.httpClient
            .post<T>(baseUrl,
                file,
                {
                    headers: this.headersForFileUpload,
                    observe: 'response',
                    withCredentials: true
                }
            )
    }

    public put<T>(baseUrl: string, body?: any): Observable<HttpResponse<T>> {
        return this.httpClient
            .put<T>(baseUrl,
                body,
                {
                    headers: this.httpHeaders,
                    observe: 'response',
                    withCredentials: true
                }
            )
    }

    public deleteBookmarks<T>(baseUrl: string, body: string[]): Observable<HttpResponse<T>> {
        return this.httpClient.delete<T>(
            this.constructUrlWithParameter(baseUrl),
            {
                headers: this.httpHeaders,
                observe: 'response',
                withCredentials: true,
                body
            })
    }

    public deleteFlamingoForms<T>(baseUrl: string, body: FlamingoFormDeleteDTO): Observable<HttpResponse<T>> {
        return this.httpClient.delete<T>(
            this.constructUrlWithParameter(baseUrl),
            {
                headers: this.httpHeaders,
                observe: 'response',
                withCredentials: true,
                body
            })
    }

    public deleteAttachments<T>(baseUrl: string, body?: string[]): Observable<HttpResponse<T>> {
        return this.httpClient.delete<T>(
            this.constructUrlWithParameter(baseUrl),
            {
                headers: this.httpHeaders,
                observe: 'response',
                withCredentials: true,
                body
            })
    }
}
